import { getErrorMessage } from "../../functions";
import { isDefined, isRecord } from "../../typechecks";
import httpStatus from "http-status";
import { ErrorName } from "../../types/errors";

/**
 * Create classes that implement this interface, that way we can choose to add
 * required properties and be reminded to implement it on all error types.
 */
interface ErrorProperties {
  statusCode: number;
}

/**
 * This custom error is a way to set the constructor name of the instances of
 * errors, so that we can have a fallback for identifying errors that work with
 * Jest. It also makes it more convenient to set both the class name and the
 * errorName property in one go. The 'name' property is special and not safe to
 * rely on for a unique identifier, but 'errorName' is our own enum which we
 * can track from backend to frontend.
 * See the function isCustomError in functions.errorHandling.ts
 */
class CustomError extends Error {
  static readonly errorName: string;
  readonly errorName: string;
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = (this.constructor as typeof CustomError).errorName;
    this.errorName = (this.constructor as typeof CustomError).errorName;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * An error caused by a failed validation.
 */
export class ValidationError extends CustomError implements ErrorProperties {
  static readonly errorName = ErrorName.ValidationError;
  statusCode: number;
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.statusCode = httpStatus.UNPROCESSABLE_ENTITY;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Error caused by either the query, params or body of a request being
 * incorrect in some way.
 */
export class BadRequestError extends CustomError implements ErrorProperties {
  static readonly errorName = ErrorName.BadRequestError;
  statusCode: number;
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.statusCode = httpStatus.BAD_REQUEST;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Error signifying a missing resource.
 */
export class NotFoundError extends CustomError implements ErrorProperties {
  static readonly errorName = ErrorName.NotFoundError;
  statusCode: number;
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.statusCode = httpStatus.NOT_FOUND;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * The user has no student object, either because none has been set up or
 * because user is a root user.
 */
export class NoStudentObjectError
  extends CustomError
  implements ErrorProperties
{
  static readonly errorName = ErrorName.NoStudentObjectError;
  statusCode: number;
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.statusCode = httpStatus.BAD_REQUEST;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * The creation of an allocation group failed due to a missconfiguration.
 */
export class StudentSetGroupConfigurationError
  extends CustomError
  implements ErrorProperties
{
  static readonly errorName = ErrorName.StudentSetGroupConfigurationError;
  statusCode: number;
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.statusCode = httpStatus.UNPROCESSABLE_ENTITY;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Represents an error that occurs if the fetch call to Pacemaker outright
 * fails. This is the scenario in which we will want to try to do add and
 * remove directly using teserver instead.
 */
export class PacemakerFetchError
  extends CustomError
  implements ErrorProperties
{
  static readonly errorName = ErrorName.PacemakerFetchError;
  statusCode: number;
  constructor(
    message?: string,
    statusCode: number = httpStatus.INTERNAL_SERVER_ERROR,
    options?: ErrorOptions
  ) {
    super(message, options);
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Represents an error that occurs if the response from pacemaker is not ok.
 */
export class PacemakerResponseError
  extends CustomError
  implements ErrorProperties
{
  static readonly errorName = ErrorName.PacemakerResponseError;
  statusCode: number;
  constructor(
    message?: string,
    statusCode: number = httpStatus.INTERNAL_SERVER_ERROR,
    options?: ErrorOptions
  ) {
    super(message, options);
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * An error caused by an external service call, or a non-ok response from one
 * such call.
 */
export class ExternalServiceError
  extends CustomError
  implements ErrorProperties
{
  static readonly errorName = ErrorName.ExternalServiceError;
  statusCode: number;
  constructor(
    message?: string,
    statusCode: number = httpStatus.INTERNAL_SERVER_ERROR,
    options?: ErrorOptions
  ) {
    super(message, options);
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * This error collects errors-as-values from the legacy error handling in
 * CatalogService, RegistrationService etc.
 */
export class RegistrationApiError
  extends CustomError
  implements ErrorProperties
{
  static readonly errorName = ErrorName.RegistrationApiError;
  statusCode: number;
  constructor(
    message?: string,
    statusCode: number = httpStatus.INTERNAL_SERVER_ERROR,
    options?: ErrorOptions
  ) {
    super(message, options);
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * The 503 error from Pacemaker that signifies that the queue is full.
 */
export class HeavyLoadError extends CustomError implements ErrorProperties {
  static readonly errorName = ErrorName.HeavyLoadError;
  statusCode: number;
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.statusCode = 503;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class MaximumMembershipReachedError
  extends CustomError
  implements ErrorProperties
{
  static readonly errorName = ErrorName.MaximumMembershipReachedError;
  statusCode: number;
  constructor(
    message?: string,
    statusCode: number = httpStatus.INTERNAL_SERVER_ERROR,
    options?: ErrorOptions
  ) {
    super(message, options);
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class MaximumMembershipReachedByTypeError
  extends CustomError
  implements ErrorProperties
{
  static readonly errorName = ErrorName.MaximumMembershipReachedByTypeError;
  statusCode: number;
  constructor(
    message?: string,
    statusCode: number = httpStatus.INTERNAL_SERVER_ERROR,
    options?: ErrorOptions
  ) {
    super(message, options);
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

type RegistrationErrorConstructor = {
  errors: unknown[];
  attemptRecovery?: () => void;
};

/**
 * Error in the Registration front end that combines multiple errors into one,
 * and allows for a custom recovery function. Intentionally not implementing
 * the ErrorProperties interface since it is dynamically assigning properties.
 */
export class RegistrationError extends CustomError {
  static readonly errorName = ErrorName.RegistrationError;
  public recover: () => void = () => location.reload();
  constructor(con: RegistrationErrorConstructor) {
    const errorMessage = con.errors.reduce((msg: string, error) => {
      return `${msg}\n${getErrorMessage(error)}`;
    }, "");
    super(errorMessage);
    Object.setPrototypeOf(this, new.target.prototype);

    // Set properties directly on the instance object
    con.errors.forEach((error) => {
      if (isRecord(error)) {
        Object.keys(error).forEach((key) => {
          // Skip the 'message'
          if (key === "message") {
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!Array.isArray((this as any)[key])) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this as any)[key] = [];
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this as any)[key].push((error as any)[key]);
        });
      }
    });

    if (isDefined(con.attemptRecovery)) {
      this.recover = con.attemptRecovery;
    }
  }
}
