import { z } from "zod";
import { isDefined } from "../typechecks";

// Jest has some trouble with Zod and says this function is not a function.
// Keep it in a a separate file so as not to infect tests with failures.

/**
 * Refine any partial schema to make sure no undefined values were explicity
 * added to any of the keys.
 */
export function partialNotUndefined<T extends z.ZodTypeAny>(
  partialSchema: T
): z.ZodEffects<T> {
  return partialSchema.superRefine((val, ctx) => {
    for (const [k, v] of Object.entries(val)) {
      // best way would be to check v === undefined,
      // but if we do that we get Jest issues with Zod that it loses that zod reference.
      if (!isDefined(v) && v !== null) {
        ctx.addIssue({
          code: "custom",
          path: [k],
          message: `${k} can be omitted, but not undefined.`,
        });
      }
    }
  });
}
