import { Action, isRejectedWithValue } from "@reduxjs/toolkit";
import {
  ErrorName,
  exhaustiveMatchingGuard,
  FailsWithTracks,
  isDefined,
  isRecord,
  TrackListWithReason,
  translations,
} from "@timeedit/registration-shared";

/**
 * Helper to identify the best label for the error message inside the
 * RTK Query error middleware, since meta.arg is of unknown type.
 */
export function identifyRTKErrorLocation(action: Action) {
  if (!isRejectedWithValue(action)) {
    return action.type;
  }

  if (!isRecord(action.meta.arg)) {
    return action.type;
  }

  if ("endpointName" in action.meta.arg) {
    return action.meta.arg.endpointName;
  }

  return "Unknown location";
}
export type ErrorTexts = {
  text: string;
  title: string;
  knownError: boolean;
  logId: string | undefined; // logId is only defined for unknown errors
};
export type GetKnownErrorTextsProps =
  | {
      kind: "failedGroups";
      failedGroups: TrackListWithReason;
      logId: string | undefined;
    }
  | {
      kind: "message";
      errorMessage: string;
      errorName: ErrorName;
      errorCode: number;
      logId: string;
    };
export function getKnownErrorTexts(props: GetKnownErrorTextsProps): ErrorTexts {
  const error: {
    message: string | undefined;
    code: number | undefined;
    name: ErrorName;
  } = {
    code: undefined,
    message: undefined,
    name: ErrorName.Error,
  };

  if (props.kind === "failedGroups") {
    const { failedGroups } = props;

    Object.keys(failedGroups).forEach((key) => {
      // We just want to show one message, so we take the first one we can find
      const failedGroup = failedGroups[key]?.find(
        (error) =>
          isDefined(error.reasons?.failsWithMessage) ||
          isDefined(error.reasons?.failsWithTracks)
      );
      const systemError = failedGroup?.reasons?.failsWithMessage?.systemError;
      if (isDefined(systemError)) {
        const systemErrorType = Object.values(systemError)[0];
        if (isDefined(systemErrorType)) {
          error.code = systemErrorType.code;
          error.message = systemErrorType.message;
          error.name = systemErrorType.name;
        }
        return;
      }

      const trackFailError = failedGroup?.reasons?.failsWithTracks;
      if (isDefined(trackFailError)) {
        // Take the first key we find, we just want to display one error message at a time
        error.message = Object.keys(trackFailError)[0];
      }
    });
  } else {
    error.message = props.errorMessage;
    error.code = props.errorCode;
    error.name = props.errorName;
  }

  if (isDefined(error.message)) {
    if (error.name === ErrorName.HeavyLoadError) {
      return {
        title: translations.highDemandErrorTitle,
        text: translations.pleaseTryAgain,
        knownError: true,
        logId: undefined,
      };
    }
    if (isFailsWithTracksKey(error.message)) {
      return failsWithTrackErrorMessage(error.message);
    }
    return failsWithSystemErrorMessage(error.name, props.logId);
  }

  // Default case which shouldn't happen.
  // Only happens if error.message is undefined.
  return {
    title: translations.errorInRegistration,
    text: translations.pleaseTryAgain,
    knownError: false,
    logId: props.logId,
  };
}

function failsWithSystemErrorMessage(
  errorName: ErrorName,
  logId: string | undefined
): ErrorTexts {
  if (
    errorName === ErrorName.MaximumMembershipReachedError ||
    errorName === ErrorName.MaximumMembershipReachedByTypeError
  ) {
    return {
      knownError: true,
      text: translations.sizeErrorText,
      title: translations.sizeErrorTitle,
      logId: undefined,
    };
  }

  return {
    title: translations.errorInRegistration,
    text: translations.defaultServerErrorMessage,
    knownError: false,
    logId,
  };
}

function isFailsWithTracksKey(key: string): key is keyof FailsWithTracks {
  return (
    key === "buffer" ||
    key === "conflict" ||
    key === "dedicated" ||
    key === "doubleBooked" ||
    key === "size" ||
    key === "hidden"
  );
}

function failsWithTrackErrorMessage(key: keyof FailsWithTracks): ErrorTexts {
  switch (key) {
    case "buffer":
    case "size":
      return {
        title: translations.sizeErrorTitle,
        text: translations.sizeErrorText,
        knownError: true,
        logId: undefined,
      };
    case "dedicated":
      return {
        title: translations.dedicatedErrorTitle,
        text: translations.dedicatedErrorText,
        knownError: true,
        logId: undefined,
      };
    // Double booked should never be an issue, but should cover the case if it does.
    case "doubleBooked":
      return {
        title: translations.doubleBookedErrorTitle,
        text: translations.doubleBookedErrorText,
        knownError: true,
        logId: undefined,
      };
    // Conflict should never be an issue, but should cover the case if it does.
    case "conflict":
      return {
        title: translations.conflictErrorTitle,
        text: translations.conflictErrorText,
        knownError: true,
        logId: undefined,
      };
    // Hidden should never be an issue, but should cover the case if it does.
    case "hidden":
      return {
        title: translations.hiddenErrorTitle,
        text: translations.hiddenErrorText,
        knownError: true,
        logId: undefined,
      };
    default:
      return exhaustiveMatchingGuard(key);
  }
}
