import { z } from "zod";

// Jest has some trouble with Zod and says this function is not a function.
// Keep it in a a separate file so as not to infect tests with failures.

/**
 * Creates a validator for records that makes values possibly undefined.
 * For use with records that have arbitrary strings as keys, which makes them
 * neither exhaustive nor type safe.
 */
export function ZUnsafeRecord<
  RecordKey extends z.ZodString | z.ZodNumber | z.ZodSymbol,
  RecordValue extends z.ZodTypeAny,
>(key: RecordKey, value: RecordValue) {
  return z.record(key, value.or(z.undefined()));
}
