import {
  isNullish,
  isErrorWithMessage,
  hasData,
  isString,
  isRecord,
  isNumber,
  isErrorWithLogId,
  isArray,
  isErrorWithErrorName,
} from "../typechecks";
import { ErrorName } from "../types/errors";
import { safeFromJSONObject, safeToJSON } from "./json";

export function getErrorLogIds(error: unknown): string | string[] {
  if (isNullish(error)) {
    return "";
  }

  if (isErrorWithLogId(error)) {
    return error.logId;
  }

  return recurse(
    error,
    (error) => {
      if (isArray(error)) {
        return error.flatMap((errData) => getErrorLogIds(errData));
      }
      return getErrorLogIds(error);
    },
    () => ""
  );
}

export function getStatusCode(error: unknown): number {
  if (isNullish(error)) {
    return 500;
  }

  if (isNumber(error)) {
    return error;
  }

  for (const key of ["statusCode", "status"]) {
    if (isRecord(error) && key in error) {
      const value = error[key];
      if (isNumber(value)) {
        return getStatusCode(value);
      }
      if (isString(value)) {
        const numberValue = parseInt(value, 10);
        return isNaN(numberValue) ? 500 : numberValue;
      }
    }
  }

  return recurse(error, getStatusCode, () => 500);
}

/**
 * Function to extract a meaningful message from errors of different types.
 */
export function getErrorMessage(error: unknown): string {
  if (isNullish(error)) {
    return "";
  }

  if (isString(error)) {
    return error;
  }

  if (isErrorWithMessage(error)) {
    return getErrorMessage(error.message);
  }

  return recurse(error, getErrorMessage, () => safeToJSON(error));
}

/**
 * Function to extract an error name from errors.
 */
export function getErrorName(error: unknown): ErrorName {
  if (isNullish(error)) {
    return ErrorName.Error;
  }

  if (isErrorWithErrorName(error)) {
    return error.errorName;
  }

  return recurse(error, getErrorName, () => ErrorName.Error);
}

/**
 *
 * @param error
 * @return The Te Server internal error code if it exists, otherwise undefined.
 */
export function getTeServerErrorCode(error: unknown): number | undefined {
  if (isNullish(error)) {
    return undefined;
  }

  if (isString(error)) {
    const numberValue = parseInt(error, 10);
    if (isNaN(numberValue)) {
      const jsonError = safeFromJSONObject(error);
      return getTeServerErrorCode(jsonError);
    }
    return numberValue;
  }

  if (isNumber(error)) {
    return error;
  }

  if (isRecord(error)) {
    if ("errorCode" in error) {
      return getTeServerErrorCode(error.errorCode);
    }
    // Pacemaker sends error code from te server in the message.
    if ("message" in error) {
      const message = error.message;
      if (isRecord(message) && "errorCode" in message) {
        return getTeServerErrorCode(message.errorCode);
      }
    }
  }

  return recurse(error, getTeServerErrorCode, () => undefined);
}

function recurse<T>(
  error: unknown,
  callback: (error: unknown) => T,
  fallback: () => T
): T {
  if (hasData(error)) {
    return callback(error.data);
  }
  if (isRecord(error) && "error" in error) {
    return callback(error.error);
  }
  if (isRecord(error) && "cause" in error) {
    return callback(error.cause);
  }
  if (isRecord(error) && "response" in error) {
    return callback(error.response);
  }
  return fallback();
}
